import React from "react";
import { useTranslation } from "react-i18next";

const Contactus = () => {
  const { t } = useTranslation("contactcomponent");

  return (
    <div>
      <div className="container px-5 py-12 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-1/2 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:mt-0 px-8 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h2 className="text-3xl lg:text-4xl font-bold mb-6">{t("Get in Touch")}</h2>
            <p className="lg:w-full mx-auto leading-relaxed text-base mb-4">
              {t("We are here to take on your questions, inquiries, or feedback! Thank you for your interest in HUMD. Let us know how we can assist you by reaching out to us through the following information:")}
            </p>
          </div>
          <form action="">
            <div className="relative mb-2">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                {t("Name")}
              </label>
              <input type="text" id="name" name="name" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
            </div>
            <div className="relative mb-2">
              <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                {t("Email")}
              </label>
              <input type="email" id="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
            </div>
            <div className="relative mb-2">
              <label htmlFor="number" className="leading-7 text-sm text-gray-600">
                {t("Contact No")}
              </label>
              <input type="number" id="number" name="number" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
            </div>
            <div className="relative mb-2">
              <label htmlFor="dropdown1" className="leading-7 text-sm text-gray-600">
                {t("Support")}
              </label>
              <select id="dropdown1" name="dropdown1" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-4 leading-8 transition-colors duration-200 ease-in-out" required>
                <option value="" className="text-sm"> </option>
                <option value="Customer Support" className="text-sm">
                  {t("Customer Support")}
                </option>
                <option value="Vendor Support" className="text-sm">
                  {t("Vendor Support")}
                </option>
                <option value="Reseller Support" className="text-sm">
                  {t("Reseller Support")}
                </option>
                <option value="Investment Support" className="text-sm">
                  {t("Investment Support")}
                </option>
              </select>
            </div>
            <div className="relative mb-4">
              <label htmlFor="message" className="leading-7 text-sm text-gray-600">
                {t("Message")}
              </label>
              <textarea id="message" name="message" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-24 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
            </div>
            <button className="text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg mx-auto justify-center flex">
              {t("Submit")}
            </button>
          </form>
        </div>
      </div>
      <div className="w-full pt-2 mt-2 border-t border-gray-200 flex justify-center flex-wrap items-center lg:mx-auto mx-4 lg:mb-0 mb-6">
        <div className="lg:w-1/3 w-full lg:mx-0 mx-8 my-2 lg:my-4">
          <h2 className="text-xl font-semibold mb-2">{t("HUMD SOLUTIONS FZ LLC")}</h2>
          <p className="leading-relaxed">
            {t("in5 Tech building, Dubai internet city,")} <br />
            {t("Dubai - United Arab Emirates.")}
          </p>
          <span>
            <span className="text-lg font-semibold">{t("Contact No:")}</span>
            <span className="leading-relaxed">+971504630141</span>
          </span>
        </div>
        <div className="lg:w-1/3 w-full mx-8 lg:mx-0 lg:pl-12">
          <h2 className="text-xl font-semibold lg:mt-4">{t("Email:")}</h2>
          <p className="leading-relaxed text-blue-800">
            <span className="font-semibold text-gray-900">{t("General Inquiries:")}</span> info@humd.co
          </p>
          <p className="leading-relaxed text-blue-800">
            <span className="font-semibold text-gray-900">{t("Customer Support:")}</span> support@humd.co
          </p>
          <p className="leading-relaxed text-blue-800">
            <span className="font-semibold text-gray-900">{t("Seller Support:")}</span> seller@humd.co
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
