import React from "react";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation("enterprisecomponent");

  return (
    <div>
      <section>
        <div className="container px-10 py-6 mx-auto">
          <div className="flex flex-col text-center w-full mb-10">
            <h2 className="text-3xl lg:text-5xl font-bold mb-6">{t("Join Us")}</h2>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-left">
              {t("We are here to take on your questions, inquiries, or feedback! Thank you for your interest in HUMD. Let us know how we can assist you by reaching out to us through the following information:")}
            </p>
          </div>
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            <form action="">
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                      {t("Name")}
                    </label>
                    <input type="text" id="name" name="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                      {t("Email")}
                    </label>
                    <input type="email" id="email" name="email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label htmlFor="company" className="leading-7 text-sm text-gray-600">
                      {t("Company")}
                    </label>
                    <input type="text" id="company" name="company" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label htmlFor="contactno" className="leading-7 text-sm text-gray-600">
                      {t("Contact No")}
                    </label>
                    <input type="number" id="contactno" name="contactno" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label htmlFor="message" className="leading-7 text-sm text-gray-600">
                      {t("Message")}
                    </label>
                    <textarea id="message" name="message" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                  </div>
                </div>
                <div className="py-4 w-full">
                  <button className="flex mx-auto text-[#ED636E] font-semibold py-2 px-8 border border-[#ED636E] focus:outline-none hover:bg-[#ED636E] hover:text-white rounded text-lg">
                    {t("Join Now")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;
