import React from "react";
import Marquee from "react-fast-marquee";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cs1 from "../assets/cusLogo/humd_In5.webp";
import cs2 from "../assets/cusLogo/humd_Icetana.webp";
import cs3 from "../assets/cusLogo/humd_ICT Circle.webp";
import cs4 from "../assets/cusLogo/humd_Jalebi.webp";
import cs5 from "../assets/cusLogo/humd_Rema Tiptop.webp";
import cs6 from "../assets/cusLogo/humd_Zofeur.webp";
import cs7 from "../assets/cusLogo/humd_3s.webp";
import cs9 from "../assets/cusLogo/humd_Ahoy.webp";
import cs11 from "../assets/cusLogo/humd_Amazon.webp";
import cs12 from "../assets/cusLogo/humd_DELL.webp";
import cs13 from "../assets/cusLogo/humd_dis.webp";
import cs14 from "../assets/cusLogo/humd_FedEx.webp";
import cs15 from "../assets/cusLogo/humd_Finanshels.webp";
import cs16 from "../assets/cusLogo/humd_Veehive.webp";
import cs17 from "../assets/cusLogo/humd_GI.webp";
import cs18 from "../assets/cusLogo/humd_Hyundai.webp";
import cs19 from "../assets/cusLogo/humd_JAMA.webp";
import cs20 from "../assets/cusLogo/humd_liv.webp";
import cs21 from "../assets/cusLogo/humd_Makaan.webp";
import {useTranslation} from "react-i18next";

// Import other images...

const CustomersSlider = () => {
  const {t} = useTranslation("testimonal");
  const images = [cs1, cs2, cs3, cs4, cs5, cs6, cs7, cs9, cs11, cs12, cs13, cs14, cs15, cs16, cs17, cs18, cs19, cs20, cs21];

  return (
    <div>
      <h2 className="text-3xl lg:text-5xl font-bold mb-10 text-center">{t("Our Customers")}</h2>
      <Marquee loop={0} speed={200}>
        {images.map((image, index) => (
          <div key={index} className="mx-3">
            <img src={image} alt="customer-logo" width={180} />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default CustomersSlider;
