import React from "react";
import icon from "../assets/logo.svg";
import {Link} from "react-router-dom";
import Globe from "../assets/globe.png";
import flag2 from "../assets/uaeflag.webp";

import {useTranslation} from "react-i18next";
const Header = () => {
  const {t, i18n} = useTranslation("header");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    console.log(i18n.language);
  };
  return (
    <div>
      <header>
        <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <Link to="/" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0 ml-3">
            <img src={icon} alt="" height={190} width={190} />
          </Link>
          <div class="md:ml-auto md:mr-auto flex flex-wrap lg:items-center text-lg lg:justify-center cursor-pointer">
            <span class="mr-5 hover:text-gray-900">
              <a href="https://humd.ae/" className="flex" target="_blank" rel="noreferrer">
                <img src={flag2} alt="" className="w-6 h-6 mr-2" />
                <span class="mr-5 hover:text-gray-900">{t("UAE Store")}</span>
              </a>
            </span>
            <span class="mr-5 hover:text-gray-900">
              <Link to="/enterprise">{t("Enterprise")}</Link>
            </span>
            <span class="mr-5 hover:text-gray-900">
              {" "}
              <Link to="/reseller">{t("Resellers")}</Link>
            </span>
            <span class="mr-5 hover:text-gray-900">
              <Link to="/vendors">{t("Vendors")}</Link>
            </span>
            <span class="mr-5 hover:text-gray-900">
              <Link to="/contactus">{t("Contact")}</Link>
            </span>
          </div>
          <div className="dropdown">
            {/* <Link to="/" activeClassName="inactive" style={{display: "flex", alignItems: "center"}}> */}
            <div onClick={() => changeLanguage(i18n.language === "en" ? "ar" : "en")} className="flex items-center cursor-pointer lg:mr-0 my-2">
              <img src={Globe} alt="Language Toggle" height={20} width={20} style={{borderRadius: "50%", marginLeft: "10px"}} />
              &nbsp; {i18n.language === "en" ? "AR" : "EN"}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
