import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation("about");

  return (
    <div>
      <section className="body-font">
        <div className="container lg:px-5 px-8 lg:py-10 pt-10 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h1 className="lg:text-4xl text-3xl font-medium title-font mb-8 text-black">
              <span className="text-green-600">HUMD</span> {t("The GCC's Largest Printing Marketplace")}
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-left">
              {t("HUMD is the largest print marketplace in the GCC, offering a trusted platform for customers to bring their visions to life. We provide complete customization, from design to printing, ensuring a seamless experience regardless of budget or time constraints. Proudly incubated by In5 tech, we're a dynamic B2B tech-enabled printing solution on a mission to empower digital and offline print and corporate gift enterprises across the UAE.")}
            </p>
          </div>
        </div>
        <div className="container px-5 py-4 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h1 className="lg:text-4xl text-3xl font-medium title-font mb-8 text-black">{t("Our Vision")}</h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-center">
              {t("To emerge as the premier printing marketplace in the MENA region")}
            </p>
          </div>
        </div>
        <div className="container px-5 py-4 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h1 className="lg:text-4xl text-3xl font-medium title-font mb-8 text-black">{t("Our Mission")}</h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-left">
              {t("To empower traditional businesses to thrive in the e-commerce landscape through education & collaboration, and unify printing companies on a single, simplified platform to drive excellence in printing customization.")}
            </p>
          </div>
        </div>
        <div className="container px-5 lg:py-10 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h1 className="lg:text-4xl text-3xl font-medium title-font mb-8 text-black">{t("Our Platform")}</h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-left">
              <span className="text-green-600 font-bold">HUMD</span> {t("'s unique tech-enabled platform is built to cater to startups, and small & medium businesses on the lookout to procure and tailor a diverse array of printed products. Be it merchandise, marketing collateral, corporate gifts, packing solutions and beyond, HUMD is the answer to all your customization needs, and is committed to bringing you the smoothest custom-printing experience in the UAE with our expertise and operational excellence.")}
              <br /> {t("We are more than a revolution in the print industry - our mission extends to transforming outdated approaches to printing, into modern, digital and dynamic solutions. We aim to simplify and curate smooth customer experiences, while also providing a dynamic platform for businesses to benefit from. But we don’t stop there! With our focus on customers being able to pick and choose right down to the last requirement, we make sure to fit their budget and their delivery requirements with operational efficiency and speed.")}
              <br />
              {t("Our goal is to provide customers and sellers with seamless, tech-enabled solutions that go beyond the traditional approach. Our cutting-edge tech platform offers real-time stats and analytics, empowering sellers to gain real insight into enhancing their services and streamlining operations. Whether it be inventory management, digital payment solutions or logistics management, we ensure our sellers reap the benefits of our powerful tech platform.")}
            </p>
          </div>
        </div>
        <div className="container px-5 py-10 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h2 className="lg:text-4xl text-3xl font-medium title-font mb-8 text-black">{t("Meet the Founders Behind HUMD")}</h2>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-left">
              <span className="text-green-600 font-bold">HUMD</span> {t("'s story starts with a spark – a shared passion for innovation and a deep understanding of the printing industry. Our founders, Zak and Vardha, embarked on a journey to transform the way printing works. Their diverse backgrounds, combining expertise in print production, human resources, and marketing, became the building blocks for something groundbreaking. Driven by a relentless focus on customer and vendor needs, they set out to revolutionise the printing experience.")}
              <br /> {t("Their vision? A solution that would address long standing pain points and redefine the printing landscape. With a clear vision to streamline the print and purchase experience, the founder’s mission took them beyond borders to the UAE, where they recognized the potential to propel their startup onto the global stage. Their unique approach and unwavering dedication to innovation have transformed HUMD into the go-to destination for seamless printing solutions. As they continue to push boundaries and enhance printing experiences across the GCC, their journey epitomises the spirit of entrepreneurship and the power of transformative ideas.")}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
