import React from "react";
const Blog = () => {
  return (
    <section class="text-gray-600 body-font py-10 text-center">
      <h2 className="text-3xl lg:text-5xl font-bold mb-8">Coming Soon..........</h2>
    </section>
  );
};

export default Blog;
